/** @jsx jsx */
import { jsx } from "theme-ui"
import { navigate } from "@reach/router"
import { useEffect } from "react"
import { Loading } from "../components"

function Index() {
  useEffect(() => navigate("/dashboard/"), [])
  return <Loading text="Loading" />
}

export default Index
